.loader {
  text-align: center;
  margin-bottom: 2rem;
}

.loader > div > svg > g > g > circle {
  stroke: #E8A870;
}

.loader.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
